var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "div",
        { staticClass: "breadcrumb" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/HomePage" } } },
                [_vm._v("首页")]
              ),
              _c(
                "el-breadcrumb-item",
                { attrs: { to: { path: "/home/LoogFor" } } },
                [_vm._v("竞买中心")]
              ),
              _c("el-breadcrumb-item", [_vm._v("竞买详情")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "image-box" }, [
              _c("img", { attrs: { src: _vm.productData.picUrl, alt: "" } }),
            ]),
            _c(
              "div",
              { staticClass: "info-right" },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.productData.productName)),
                ]),
                !_vm.isStart(_vm.productData.startTime)
                  ? _c("div", { staticClass: "count-down-box" }, [
                      _c("span", { staticClass: "tit" }, [
                        _vm._v(
                          _vm._s(
                            _vm.isStart(_vm.productData.startTime)
                              ? "进行中"
                              : "预告中"
                          )
                        ),
                      ]),
                      _c("div", { staticClass: "count-down-info" }, [
                        _c("span", [_vm._v("距离开始仅剩：")]),
                        _vm.startTime.day > 0
                          ? _c("span", { staticClass: "time" }, [
                              _vm._v(_vm._s(_vm.startTime.day)),
                            ])
                          : _vm._e(),
                        _vm.startTime.day > 0
                          ? _c("span", { staticClass: "unit" }, [_vm._v("天")])
                          : _vm._e(),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.startTime.hour)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("时")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.startTime.minute)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("分")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.startTime.second)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("秒")]),
                      ]),
                    ])
                  : _c("div", { staticClass: "count-down-box" }, [
                      _c("span", { staticClass: "tit" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatNewDate(_vm.productData.validDate) >
                              new Date()
                              ? "进行中"
                              : "已结束"
                          ) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "count-down-info" }, [
                        _c("span", [_vm._v("距离结束仅剩：")]),
                        _vm.endTime.day > 0
                          ? _c("span", { staticClass: "time" }, [
                              _vm._v(_vm._s(_vm.endTime.day)),
                            ])
                          : _vm._e(),
                        _vm.endTime.day > 0
                          ? _c("span", { staticClass: "unit" }, [_vm._v("天")])
                          : _vm._e(),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.endTime.hour)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("时")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.endTime.minute)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("分")]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm.endTime.second)),
                        ]),
                        _c("span", { staticClass: "unit" }, [_vm._v("秒")]),
                      ]),
                    ]),
                _c("div", { staticClass: "box1-warp" }, [
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("产品类型")]),
                    _c("p", [_vm._v(_vm._s(_vm.productData.categoryName))]),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("规格型号")]),
                    _c("p", [_vm._v(_vm._s(_vm.productData.productSpec))]),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("需求量")]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.productData.reqNum) +
                          _vm._s(_vm.productData.unit)
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("最高竞买价")]),
                    _c("p", [_vm._v(_vm._s(_vm.productData.maxPrice) + "元")]),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("降价幅度")]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.productData.stepPrice) + "元/次"),
                    ]),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("交货时间")]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.productData.deliverDay) + "天"),
                    ]),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("开始时间")]),
                    _vm.productData.startTime
                      ? _c("p", [
                          _vm._v(
                            _vm._s(_vm.productData.startTime.replace("T", " "))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("结束时间")]),
                    _vm.productData.validDate
                      ? _c("p", [
                          _vm._v(
                            _vm._s(_vm.productData.validDate.replace("T", " "))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("订单模式")]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.productData.orderProcess === "KH"
                            ? "先款后货"
                            : "先货后款"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("支付模式")]),
                    _c("p", [_vm._v(_vm._s(_vm.productData.payMode))]),
                  ]),
                  _vm.productData.payMode == "CDHP"
                    ? _c("div", { staticClass: "box1" }, [
                        _c("span", [_vm._v("承兑期限")]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.productData.bankBillTerm
                                ? _vm.productData.bankBillTerm + "个月"
                                : "无限制"
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "box1" }, [
                    _c("span", [_vm._v("合同编号")]),
                    _c("p", [_vm._v(_vm._s(_vm.productData.contractNumber))]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "box1",
                      staticStyle: { "padding-left": "20px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.showContractMode },
                        },
                        [_vm._v("查看合同模板")]
                      ),
                    ],
                    1
                  ),
                ]),
                !_vm.isStart(_vm.productData.validDate)
                  ? _c(
                      "div",
                      {
                        staticClass: "addCart",
                        class: {
                          "no-start": !_vm.isStart(_vm.productData.startTime),
                        },
                        on: { click: _vm.showQuoteDialog },
                      },
                      [_vm._v("立即报价")]
                    )
                  : _vm.clientAlike
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path: "/User/TransactionDetail",
                            query: {
                              orderid: _vm.orderId,
                              customer: _vm.customer,
                              orderType: _vm.orderType,
                            },
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "addCart",
                            class: {
                              "no-start": !_vm.isStart(
                                _vm.productData.startTime
                              ),
                            },
                          },
                          [_vm._v("进入订单")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.showPriceFail
                  ? _c("div", { staticClass: "addCart no-start" }, [
                      _vm._v("报价未成交"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.orderInfo
            ? _c("div", { staticClass: "explain" }, [
                _c("h3", [_vm._v("订单流程")]),
                ["QXC", "QXS"].indexOf(_vm.orderInfo.orderStatus) < 0
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          background: "#fff",
                          padding: "20px 20px 10px",
                        },
                      },
                      [
                        _c(
                          "el-steps",
                          {
                            attrs: { active: _vm.flowStep, "align-center": "" },
                          },
                          [
                            _c("el-step", { attrs: { title: "确认订单" } }),
                            _c("el-step", { attrs: { title: "合同签署" } }),
                            _c("el-step", { attrs: { title: "供应商发货" } }),
                            _c("el-step", { attrs: { title: "买家签收" } }),
                            _c("el-step", { attrs: { title: "供应商开发票" } }),
                            _c("el-step", { attrs: { title: "买家付款" } }),
                            _c("el-step", { attrs: { title: "供应商收款" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "explain-info" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.orderInfo.orderStatu === "QXC"
                              ? "订单取消待确认"
                              : "订单已取消成功"
                          ) +
                          " "
                      ),
                    ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "explain" }, [
            _c("h3", [_vm._v("竞买要求")]),
            _c(
              "div",
              { staticClass: "explain-info" },
              [
                _vm.isLogin
                  ? _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.productData.reqRemark),
                      },
                    })
                  : _c("empty", { attrs: { text: "登录后可见" } }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "sidebar" }, [
          _c("div", { staticClass: "publish" }, [
            _c("h3", [_vm._v("企业信息")]),
            _c("div", { staticClass: "publish-info" }, [
              _c("p", [
                _vm._v(
                  "公司名称：" +
                    _vm._s(
                      _vm._f("hideTextInfo")(
                        _vm.productData.clientName,
                        3,
                        4
                      ) || "--"
                    )
                ),
              ]),
              _c("p", [
                _vm._v(
                  "公司地址：" +
                    _vm._s(
                      _vm._f("hideTextInfo")(
                        _vm.productData.clientAddress,
                        3,
                        4
                      ) || "--"
                    )
                ),
              ]),
              _c("p", [
                _vm._v(
                  "企业认证：" +
                    _vm._s(_vm.productData.clientStatus ? "已认证" : "未认证")
                ),
              ]),
              _c("p", [
                _vm._v(
                  "联系电话：" +
                    _vm._s(
                      _vm._f("hideTextInfo")(_vm.productData.phone, 3, 4) ||
                        "--"
                    )
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "publish", staticStyle: { "margin-top": "20px" } },
            [
              _c("h3", [_vm._v("最新铝锭价")]),
              _c("div", { staticClass: "publish-info" }, [
                _c("p", [
                  _vm._v(" 南海灵通铝锭价(含票)："),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("￥" + _vm._s(_vm.lvPrice.taxPrice || 0)),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "铝锭价公布日期：" + _vm._s(_vm.lvPrice.publishDay || "--")
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "publish", staticStyle: { "margin-top": "20px" } },
            [
              _c("h3", [_vm._v("已报价企业")]),
              _c(
                "div",
                { staticClass: "price-list" },
                [
                  _vm.isLogin
                    ? _c(
                        "div",
                        _vm._l(_vm.priceList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "price-item" },
                            [
                              _c("div", { staticClass: "title" }, [
                                item.client_info_id ===
                                _vm.userInfo.enterpriseId
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "16px" },
                                      },
                                      [_vm._v(_vm._s(item.client_name))]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "16px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("hideTextInfo")(
                                              item.client_name,
                                              3,
                                              4
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "16px" } },
                                [_vm._v(_vm._s(item.add_time))]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "3px" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-weight": "bold",
                                      },
                                    },
                                    [_vm._v("￥" + _vm._s(item.amount))]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("empty", { attrs: { text: "登录后可见" } }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "报价",
            visible: _vm.quoteDialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.quoteDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "quoteForm",
                  attrs: {
                    model: _vm.quoteForm,
                    rules: _vm.quoteFormRules,
                    "label-width": "160px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "最高竞买价(元)：", prop: "proNum" } },
                    [_vm._v(" " + _vm._s(_vm.productData.maxPrice) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "当前最低价元)：", prop: "proNum" } },
                    [_vm._v(" " + _vm._s(_vm.minPrice) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "当前报价(元)：", prop: "amount" } },
                    [
                      _c("div", { staticClass: "num-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "min",
                            class: { disable: _vm.miunsBtnDisable },
                            on: { click: _vm.minusAmount },
                          },
                          [_c("i", { staticClass: "el-icon-minus" })]
                        ),
                        _c(
                          "div",
                          { staticClass: "num", attrs: { type: "text" } },
                          [_vm._v(_vm._s(_vm.quoteForm.amount))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "add",
                            class: { disable: _vm.addBtnDisable },
                            on: { click: _vm.addAmount },
                          },
                          [_c("i", { staticClass: "el-icon-plus" })]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.quoteDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmQuote } },
                [_vm._v("确认报价")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "合同模板",
            visible: _vm.contractDialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.contractDialogVisible = $event
            },
          },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.contractTextModel) } })]
      ),
      _c("CFCATool", {
        ref: "CFCARef",
        attrs: { id: "CFCAId" },
        on: {
          getCertInfo: function ($event) {
            _vm.certInfo = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }