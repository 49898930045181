<template>
  <div class="wrap">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path: '/home/HomePage'}">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/home/LoogFor'}">竞买中心</el-breadcrumb-item>
        <el-breadcrumb-item>竞买详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <div class="main">
        <div class="info">
          <div class="image-box">
            <img :src="productData.picUrl" alt="" />
          </div>

          <div class="info-right">
            <div class="name">{{ productData.productName }}</div>
            <div class="count-down-box" v-if="!isStart(productData.startTime)">
              <span class="tit">{{ isStart(productData.startTime) ? '进行中' : '预告中' }}</span>
              <div class="count-down-info">
                <span>距离开始仅剩：</span>
                <span class="time" v-if="startTime.day > 0">{{ startTime.day }}</span>
                <span class="unit" v-if="startTime.day > 0">天</span>
                <span class="time">{{ startTime.hour }}</span>
                <span class="unit">时</span>
                <span class="time">{{ startTime.minute }}</span>
                <span class="unit">分</span>
                <span class="time">{{ startTime.second }}</span>
                <span class="unit">秒</span>
              </div>
            </div>
            <div class="count-down-box" v-else>
              <span class="tit">{{ formatNewDate(productData.validDate) > new Date() ? '进行中' : '已结束' }} </span>
              <div class="count-down-info">
                <span>距离结束仅剩：</span>
                <span class="time" v-if="endTime.day > 0">{{ endTime.day }}</span>
                <span class="unit" v-if="endTime.day > 0">天</span>
                <span class="time">{{ endTime.hour }}</span>
                <span class="unit">时</span>
                <span class="time">{{ endTime.minute }}</span>
                <span class="unit">分</span>
                <span class="time">{{ endTime.second }}</span>
                <span class="unit">秒</span>
              </div>
            </div>
            <div class="box1-warp">
              <div class="box1">
                <span>产品类型</span>
                <p>{{ productData.categoryName }}</p>
              </div>
              <div class="box1">
                <span>规格型号</span>
                <p>{{ productData.productSpec }}</p>
              </div>
              <div class="box1">
                <span>需求量</span>
                <p>{{ productData.reqNum }}{{ productData.unit }}</p>
              </div>
              <div class="box1">
                <span>最高竞买价</span>
                <p>{{ productData.maxPrice }}元</p>
              </div>
              <div class="box1">
                <span>降价幅度</span>
                <p>{{ productData.stepPrice }}元/次</p>
              </div>
              <div class="box1">
                <span>交货时间</span>
                <p>{{ productData.deliverDay }}天</p>
              </div>
              <div class="box1">
                <span>开始时间</span>
                <p v-if="productData.startTime">{{ productData.startTime.replace('T', ' ') }}</p>
              </div>
              <div class="box1">
                <span>结束时间</span>
                <p v-if="productData.validDate">{{ productData.validDate.replace('T', ' ') }}</p>
              </div>

              <div class="box1">
                <span>订单模式</span>
                <p>{{ productData.orderProcess === 'KH' ? '先款后货' : '先货后款' }}</p>
              </div>
              <div class="box1">
                <span>支付模式</span>
                <!-- <p >{{productData.payMode === 'CDHP' ?'商业承兑汇票':'银行转账'}}</p> -->
                <p>{{ productData.payMode }}</p>
              </div>
              <div class="box1" v-if="productData.payMode == 'CDHP'">
                <span>承兑期限</span>
                <p>{{ productData.bankBillTerm ? productData.bankBillTerm + '个月' : '无限制' }}</p>
              </div>
              <div class="box1">
                <span>合同编号</span>
                <p>{{ productData.contractNumber }}</p>
              </div>
              <div class="box1" style="padding-left: 20px">
                <el-button type="text" @click="showContractMode">查看合同模板</el-button>
              </div>
            </div>
            <div class="addCart" v-if="!isStart(productData.validDate)" @click="showQuoteDialog" :class="{'no-start': !isStart(productData.startTime)}">立即报价</div>
            <router-link v-else-if="clientAlike" :to="{path: '/User/TransactionDetail', query: {orderid: orderId, customer: customer, orderType: orderType}}">
              <div class="addCart" :class="{'no-start': !isStart(productData.startTime)}">进入订单</div>
            </router-link>
            <div class="addCart no-start" v-if="showPriceFail">报价未成交</div>
          </div>
        </div>
        <!--          <div v-permission="{either:true,value:['user:buy:*','login']}">-->

        <!-- 订单流程 -->
        <div v-if="orderInfo" class="explain">
          <h3>订单流程</h3>
          <div v-if="['QXC', 'QXS'].indexOf(orderInfo.orderStatus) < 0" style="background:#fff;padding:20px 20px 10px">
            <el-steps :active="flowStep" align-center>
              <el-step title="确认订单"></el-step>
              <el-step title="合同签署"></el-step>
              <el-step title="供应商发货"></el-step>
              <el-step title="买家签收"></el-step>
              <el-step title="供应商开发票"></el-step>
              <el-step title="买家付款"></el-step>
              <el-step title="供应商收款"></el-step>
              <!-- <el-step title="流程结束"></el-step> -->
            </el-steps>
          </div>
          <div class="explain-info" v-else>
            {{ orderInfo.orderStatu === 'QXC' ? '订单取消待确认' : '订单已取消成功' }}
          </div>
        </div>
        <div class="explain">
          <h3>竞买要求</h3>
          <div class="explain-info">
            <p v-if="isLogin" v-html="productData.reqRemark"></p>
            <empty v-else text="登录后可见"></empty>
          </div>
        </div>
        <!-- <div class="explain">
              <h3>已报价企业</h3>
              <div class="explain-info">
                <div v-if="isLogin">
                  <p
                    v-for="(item, index) in priceList"
                    :key="index" >
                      <span style="margin-right: 16px;">{{item.add_time}}</span>
                      <span style="margin-right: 16px;" v-if="item.client_info_id === userInfo.enterpriseId">{{item.client_name}}</span>
                      <span style="margin-right: 16px;" v-else>{{item.client_name | hideTextInfo(3, 4)}}</span>
                      <span style="color: red;font-weight: bold;">￥{{item.amount}}</span>
                  </p>
                </div>
                <empty v-else text="登录后可见"></empty>
              </div>
          </div> -->
      </div>

      <div class="sidebar">
        <div class="publish">
          <h3>企业信息</h3>
          <div class="publish-info">
            <p>公司名称：{{ productData.clientName | hideTextInfo(3, 4) || '--' }}</p>
            <p>公司地址：{{ productData.clientAddress | hideTextInfo(3, 4) || '--' }}</p>
            <p>企业认证：{{ productData.clientStatus ? '已认证' : '未认证' }}</p>
            <p>联系电话：{{ productData.phone | hideTextInfo(3, 4) || '--' }}</p>
            <!-- <p>
                联系电话：
                <span v-if="!isShowPhone">{{hidePhoneText || '--'}}</span>
                <span v-if="isShowPhone">{{productData.phone || '--'}}</span>
                <span v-if="!isShowPhone && hidePhoneText" @click="showPhone" class="see">点击查看</span>
            </p> -->
          </div>
        </div>
        <div class="publish" style="margin-top:20px">
          <h3>最新铝锭价</h3>
          <div class="publish-info">
            <p>
              南海灵通铝锭价(含票)：<span style="color: red;">￥{{ lvPrice.taxPrice || 0 }}</span>
            </p>
            <p>铝锭价公布日期：{{ lvPrice.publishDay || '--' }}</p>
          </div>
        </div>
        <div class="publish" style="margin-top:20px">
          <h3>已报价企业</h3>
          <div class="price-list">
            <div v-if="isLogin">
              <div class="price-item" v-for="(item, index) in priceList" :key="index">
                <div class="title">
                  <span style="margin-right: 16px;" v-if="item.client_info_id === userInfo.enterpriseId">{{ item.client_name }}</span>
                  <span style="margin-right: 16px;" v-else>{{ item.client_name | hideTextInfo(3, 4) }}</span>
                </div>

                <span style="margin-right: 16px;">{{ item.add_time }}</span>
                <div style="margin-top:3px">
                  <span style="color: red;font-weight: bold;">￥{{ item.amount }}</span>
                </div>
              </div>
            </div>
            <empty v-else text="登录后可见"></empty>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="报价" :visible.sync="quoteDialogVisible" width="500px">
      <div>
        <el-form :model="quoteForm" ref="quoteForm" :rules="quoteFormRules" label-width="160px">
          <!-- <el-form-item label="供货量：" prop="proNum">
                    <el-input class="input-width" disabled @blur="changeAmount" v-model="quoteForm.proNum" type="number"></el-input>
                    {{productData.unit}}
                </el-form-item> -->
          <el-form-item label="最高竞买价(元)：" prop="proNum">
            {{ productData.maxPrice }}
          </el-form-item>
          <el-form-item label="当前最低价元)：" prop="proNum">
            {{ minPrice }}
          </el-form-item>
          <el-form-item label="当前报价(元)：" prop="amount">
            <div class="num-box">
              <div class="min" :class="{disable: miunsBtnDisable}" @click="minusAmount"><i class="el-icon-minus"></i></div>
              <div class="num" type="text">{{ quoteForm.amount }}</div>
              <div class="add" :class="{disable: addBtnDisable}" @click="addAmount"><i class="el-icon-plus"></i></div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="报价说明："
                        prop="proRemark">
            <el-input class="input-width"
                      rows="5"
                      v-model="quoteForm.proRemark"
                      type="textarea"
                      :placeholder="`请根据提示内容填写相关备注：
1、交货期；
2、交货方式、地点；
3、结算方式：发票、数期等；
4、货物外观质量备注。`"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quoteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmQuote">确认报价</el-button>
      </span>
    </el-dialog>

    <!-- 查看合同模板 -->
    <el-dialog title="合同模板" :visible.sync="contractDialogVisible" width="700px">
      <div v-html="contractTextModel"></div>
    </el-dialog>
    <CFCATool id="CFCAId" ref="CFCARef" @getCertInfo="certInfo = $event" />
  </div>
</template>

<script>
import {addSingleProductQuote, getProducDetail, getCurrentMinPrice, isMyOrder, newestLvdingPrice, showContractMode} from '../api';
import {hideTextInfo, formatNewDate} from '@/utils/util';
import empty from '../component/empty';
import CFCATool from '@/components/CFCATool';

export default {
  components: {
    empty,
    CFCATool
  },
  data() {
    return {
      contractDialogVisible: false,
      contractTextModel: '',
      showPriceFail: false,
      isMake: true,
      isLogin: Boolean(localStorage.getItem('loginName')),
      isSupplier: localStorage.getItem('accountType') === 'SELL',
      userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
      isShowPhone: false,
      productData: {},
      priceList: [],
      quoteForm: {
        productId: '',
        proNum: '',
        amount: '',
        clientInfoId: '',
        proRemark: '',
        price: ''
      },
      quoteFormRules: {
        // proNum: [{ required: true, message: '请输入供货量', trigger: 'blur' }],
        // price: [{ required: true, message: '请输入单价(元)', trigger: 'blur' }],
        amount: [{required: true, message: '请输入总额(元)', trigger: 'blur'}]
      },
      quoteDialogVisible: false,
      startTime: {
        day: 0,
        hour: '00',
        minute: '00',
        second: '00',
        timer: null
      },
      endTime: {
        day: 0,
        hour: '00',
        minute: '00',
        second: '00',
        timer: null
      },
      minPrice: 0,
      miunsBtnDisable: false,
      addBtnDisable: true,
      certInfo: '',
      //判断拍下的公司是否和登录人公司相同
      clientAlike: false,
      //当前商品成交的订单id
      orderId: undefined,
      customer: '',
      orderType: 'RE',
      flowStep: 0,
      orderInfo: null,
      lvPrice: {}
    };
  },
  filters: {
    hideTextInfo
  },
  computed: {
    hidePhoneText() {
      let tel = this.productData.phone || '';
      if (tel) {
        return `${tel.substr(0, 3)}****${tel.substr(7, 4)}`;
      } else {
        return '';
      }
    }
  },
  created() {
    scrollTo(0, 0);
    this.getProducDetail();
    if (this.isLogin) {
      this.getPriceList();
      this.newestLvdingPrice();
    }
  },
  methods: {
    formatNewDate,
    isStart(startTime) {
      if (startTime) {
        let date = formatNewDate(startTime);
        return date.getTime() < new Date().getTime();
      } else {
        return true;
      }
    },
    showQuoteDialog() {
      if (!this.isStart(this.productData.startTime)) return;

      if (this.$refs.CFCARef.checkIsIE()) {
        // 获取证书序列码
        if (!this.$refs.CFCARef.getCertInfo()) return;
        // 检测是否读取证书序列码
        if (!this.certInfo) return this.$message.info('未检测到证书信息');
      } else {
        this.$refs.CFCARef.getCertInfo();
        if (!this.certInfo) return;
        //  this.$message.info('未检测到证书信息')
      }
      localStorage.setItem('certInfo', this.certInfo);

      if (!this.isLogin) {
        this.$confirm('当前未登录, 是否登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            path: '/home/Login'
          });
        });
        return;
      }
      if (!this.isSupplier) return this.$message.warning('非供应商不能报价');

      this.getCurrentMinPrice();
      this.quoteDialogVisible = true;
    },
    getCurrentMinPrice() {
      getCurrentMinPrice({
        productId: this.productData.id
      }).then(res => {
        if (res.errno === 0) {
          this.quoteForm.amount = this.minPrice = res.data;
        }
      });
    },
    getProducDetail() {
      getProducDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.errno === 0) {
        this.productData = res.data.repProductDetail || {};

         let changePayMode =  this.productData.payMode.split('|').map((item)=>{
         if (item == 'YHZZ') {
        return    item = '银行转账'
          } else if (item == 'CDHP') {
        return   item = '商业承兑汇票'
          } else if (item == "YHHP") {
         return   item = '银行承兑汇票'
          }    
      })
          this.productData.payMode=changePayMode.join("|")
          
          this.quoteForm.productId = res.data.repProductDetail.id;
          this.quoteForm.clientInfoId = res.data.repProductDetail.clientInfoId;
          this.quoteForm.proNum = res.data.repProductDetail.reqNum;
          this.isStart(this.productData.startTime) ? this.countdown(this.productData.validDate, this.endTime) : this.countdown(this.productData.startTime, this.startTime);
          // 判断是否是已经结束的竞买商品
          if (this.isStart(this.productData.validDate)) {
            isMyOrder({id: this.productData.id, orderType: this.orderType}).then(response => {
              this.orderId = response.data.order.id;
              this.clientAlike = response.data.clientAlike;
              this.customer = response.data.customer;
              this.orderInfo = response.data.order;
              if (response.data.order) {
                ['DFWQ', 'DQS'].indexOf(response.data.order.orderStatus) >= 0 && (this.flowStep = 1);
                ['DFH'].indexOf(response.data.order.orderStatus) >= 0 && (this.flowStep = 2);
                ['DSH'].indexOf(response.data.order.orderStatus) >= 0 && (this.flowStep = 3);
                ['DKP'].indexOf(response.data.order.orderStatus) >= 0 && (this.flowStep = 4);
                ['DZF'].indexOf(response.data.order.orderStatus) >= 0 && (this.flowStep = 5);
                ['ZFC'].indexOf(response.data.order.orderStatus) >= 0 && (this.flowStep = 6);
                ['END'].indexOf(response.data.order.orderStatus) >= 0 && (this.flowStep = 7);
              }
            });
            // 判断是否显示报价失败
            if (this.productData.isMake === 'Y') {
              const curUserId = localStorage.getItem('userId');
              const isRight1 = res.data.priceList.some(item => item.login_user_id == curUserId);
              const isRight2 = res.data.priceList.some(item => item.is_bargain == 'T' && item.login_user_id != curUserId);
              if (isRight1 && isRight2) {
                this.showPriceFail = true;
              }
            }
          }
        }
      });
    },
    getPriceList() {
      getProducDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.errno === 0) {
          this.priceList = res.data.priceList || [];

          setTimeout(() => {
            if (this.$route.query.id) {
              this.getPriceList();

              if ((this.isStart(this.productData.startTime) && !this.endTime.timer) || (!this.isStart(this.productData.startTime) && !this.startTime.timer)) {
                this.productData = res.data.repProductDetail || {};

                let changePayMode =  this.productData.payMode.split('|').map((item)=>{
         if (item == 'YHZZ') {
        return    item = '银行转账'
          } else if (item == 'CDHP') {
        return   item = '商业承兑汇票'
          } else if (item == "YHHP") {
         return   item = '银行承兑汇票'
          }    
      })
                this.productData.payMode=changePayMode.join("|")
                this.quoteForm.productId = res.data.repProductDetail.id;
                this.quoteForm.clientInfoId = res.data.repProductDetail.clientInfoId;
                this.quoteForm.proNum = res.data.repProductDetail.reqNum;
                this.isStart(this.productData.startTime) ? this.countdown(this.productData.validDate, this.endTime) : this.countdown(this.productData.startTime, this.startTime);
              }
            }
          }, 1000);
        }
      });
    },
    showPhone() {
      this.isShowPhone = true;
    },
    // 倒计时
    countdown(endTime, obj) {
      let timer = null;
      const end = Date.parse(formatNewDate(endTime));
      // 清除定时器
      if (obj.timer) {
        clearInterval(obj.timer);
        obj.timer = null;
      }
      const now = Date.parse(new Date());
      const msec = end - now;
      // 时间结束不执行
      if (msec <= 0) return;

      // 生成定时器
      timer = setInterval(() => {
        const now = Date.parse(new Date());
        const msec = end - now;
        let d = parseInt(msec / 1000 / 60 / 60 / 24);
        let h = parseInt((msec / 1000 / 60 / 60) % 24);
        let m = parseInt((msec / 1000 / 60) % 60);
        let s = parseInt((msec / 1000) % 60);
        obj.day = d;
        obj.hour = h > 9 ? h : '0' + h;
        obj.minute = m > 9 ? m : '0' + m;
        obj.second = s > 9 ? s : '0' + s;
        if (msec <= 0) {
          clearTimeout(timer);
          obj.timer = null;
          return;
        }
      }, 1000);
      obj.timer = timer;
    },
    confirmQuote() {
      this.$refs.quoteForm.validate(valid => {
        if (valid) {
          if (this.quoteForm.amount > this.minPrice) return this.$message.warning(`当前报价需小于最低报价${this.minPrice}元，报价失败，请重新报价`);

          addSingleProductQuote([this.quoteForm]).then(res => {
            this.quoteDialogVisible = false;
            if (res.data.errno === 0) {
              this.$message({
                type: 'success',
                message: '发布成功！'
              });
            } else if (res.data.errno === -1) {
              this.getCurrentMinPrice();
            }
          });
        }
      });
    },
    changeAmount() {
      this.quoteForm.amount = this.quoteForm.proNum * this.quoteForm.price;
    },
    minusAmount() {
      let val = Number(this.quoteForm.amount);
      val -= this.productData.stepPrice || 10;
      if (val >= this.minPrice) {
        this.addBtnDisable = true;
      } else {
        this.addBtnDisable = false;
      }
      if (val <= this.minPrice * 0.7) {
        this.quoteForm.amount = Number((Math.round(this.minPrice * 70) / 100).toFixed(2));
        if (this.quoteForm.amount < this.minPrice * 0.7) {
          this.quoteForm.amount += 0.01;
        }
        this.miunsBtnDisable = true;
      } else {
        this.quoteForm.amount = Number((Math.round(val * 100) / 100).toFixed(2));
        this.miunsBtnDisable = false;
      }
    },
    addAmount() {
      let val = Number(this.quoteForm.amount);
      val += this.productData.stepPrice || 10;
      if (val <= this.minPrice * 0.7) {
        this.miunsBtnDisable = true;
      } else {
        this.miunsBtnDisable = false;
      }
      if (val >= this.minPrice) {
        this.quoteForm.amount = Number((Math.round(this.minPrice * 100) / 100).toFixed(2));
        this.addBtnDisable = true;
      } else {
        this.quoteForm.amount = Number((Math.round(val * 100) / 100).toFixed(2));
        this.addBtnDisable = false;
      }
    },
    newestLvdingPrice() {
      newestLvdingPrice().then(response => {
        this.lvPrice = response.data;
      });
    },
    //查询合同模板
    showContractMode() {
      if (this.contractTextModel == '') {
        showContractMode({contractNo: this.productData.contractInfo}).then(res => {
          this.contractTextModel = res.data.contractTextModel;
          this.contractDialogVisible = true;
        });
      } else {
        this.contractDialogVisible = true;
      }
    }
  },
  // 生命周期 - 卸载前
  beforeDestroy() {
    // 当离开页面时，清除倒计时
    clearTimeout(this.startTime.timer);
    clearTimeout(this.endTime.timer);
    localStorage.removeItem('certInfo');
    this.startTime.timer = null;
    this.endTime.timer = null;
  }
};
</script>

<style lang="less" scoped>
@deep: ~'>>>';
.head_h3 {
  position: relative;
  padding: 16px 0 16px 20px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 18px;
  color: #333333;
  margin: 0;

  &::after {
    position: absolute;
    left: 0;
    top: 50%;
    content: '';
    width: 4px;
    height: 20px;
    background-image: linear-gradient(to bottom, #acb2ca, #33a2c5);
    margin-top: -10px;
  }
}

.wrap {
  width: 1200px;
  margin: 0 auto 30px auto;

  .breadcrumb {
    margin: 18px 0;
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    overflow: hidden;
    position: relative;

    .main {
      flex: 1;

      .info {
        background: #fff;
        padding: 22px;
        display: flex;
        justify-content: space-between;

        .image-box {
          width: 340px;
          height: 340px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
        .info-right {
          flex: 1;
          margin-left: 24px;
          padding-top: 8px;

          .name {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .count-down-box {
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            margin-top: 24px;
            background: #6ac0d7;
            background-image: linear-gradient(to right, #2b92b4, transparent), repeating-linear-gradient(-45deg, rgba(80, 80, 80, 0.1), hsla(0, 0%, 100%, 0.1) 20px, transparent 0, transparent 40px);

            .tit {
              width: 60px;
              height: 100%;
              color: #ffffff;
              font-size: 16px;
              background: #2689aa;
              padding: 10px;
              box-sizing: border-box;
              text-align: center;
              line-height: 19px;
            }
            .count-down-info {
              display: flex;
              align-items: center;
              padding-left: 26px;
              font-size: 14px;
              color: #ffffff;

              .time {
                font-size: 16px;
              }
              .unit {
                color: #eed990;
                margin: 0 4px;
              }
            }
          }
          .box1-warp {
            margin-top: 24px;

            .box1 {
              display: flex;
              align-items: center;
              margin-bottom: 14px;
              // font-size: 14px;

              span {
                width: 110px;
                text-align: justify;
                text-align-last: justify;
                color: #999999;
                padding: 0 22px;
                box-sizing: border-box;
              }
              p {
                color: #333333;

                &.price {
                  color: #ff4400;
                  font-weight: bold;
                  font-size: 22px;
                }
              }
              &.price {
                background: #effaff;
                padding: 16px 0;
                margin-top: 30px;

                p {
                  color: #fe4301;
                  font-weight: bold;
                  font-size: 26px;
                }
              }
            }
          }
          .addCart {
            width: 152px;
            height: 54px;
            color: #fff;
            text-align: center;
            line-height: 54px;
            font-weight: bold;
            border-radius: 3px;
            margin: 24px 0 0 22px;
            background-image: linear-gradient(to right, #2689aa, #288cad);
            cursor: pointer;
            font-size: 16px;

            &.no-start {
              cursor: not-allowed;
              background-image: linear-gradient(to right, #554f51, #5e5657);
            }
          }
        }
      }
      .explain {
        background: #fff;
        margin-top: 16px;

        h3 {
          .head_h3();
          font-size: 14px;
        }
        .explain-info {
          padding: 24px;

          & @{deep} {
            color: #333333;
            font-size: 14px;
            line-height: 24px;
          }
          & @{deep} img {
            width: 100%;
          }
        }
      }
    }

    .sidebar {
      width: 287px;
      margin-left: 15px;

      .publish {
        background: #fff;
        // margin-top:20px;
        h3 {
          .head_h3();
          font-size: 14px;
        }
        .publish-info {
          padding: 24px;
          // font-size: 14px;
          // font-weight: bold;
          color: #333333;

          p {
            margin-bottom: 20px;

            .see {
              color: #2ca2c6;
              cursor: pointer;
              margin-left: 16px;
            }
          }
        }
        .price-list {
          padding: 24px;
          box-sizing: border-box;

          .price-item {
            padding: 5px 0;

            .title {
              margin-bottom: 6px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .bidding-status {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      .status-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .status-box-info {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .el-icon-success {
            font-size: 44px;
            color: #55c176;
            margin-right: 10px;
          }
          .el-icon-error {
            font-size: 44px;
            color: #d91516;
            margin-right: 10px;
          }
          .txst {
            font-size: 16px;
          }
          .desc {
            font-size: 13px;
            margin-top: 4px;
          }
        }
      }
    }
  }

  .input-width {
    width: 250px;
  }

  .num-box {
    display: flex;
    align-items: center;
    width: 248px;
    border: 1px solid #e4e4e4;

    .min,
    .add {
      width: 34px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      background: #f6f7fb;

      &.disable {
        cursor: not-allowed;
        background: #eeeeee;
      }
    }
    .num {
      flex: 1;
      border: 1px solid #e4e4e4;
      border-top: 0;
      border-bottom: 0;
      text-align: center;
    }
  }
}
</style>
